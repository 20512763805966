
  import { Component, Vue } from 'vue-property-decorator'
  import AppBarItem from '@/components/app/BarItem.vue'

@Component({
  components: {
    AppBarItem,
  },
})
  export default class Notifications extends Vue {
  notifications: Array<string> = [
    'Mike John Responded to your email',
    'You have 5 new tasks',
    "You're now friends with Andrew",
    'Another Notification',
    'Another one',
  ];
  }
